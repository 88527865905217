<template>
  <div>
    <a-row :gutter="16">
      <a-col :span="24">
        <a-form-item label="Nama Izin">
            <a-input v-model="dataIzin.nama" size="large" />
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item label="Tipe">
          <a-select size="large" class="w-100" :value="dataIzin.tipe" @change="(e) => handleChange(e, 'tipe')">
            <a-select-option v-for="(item, index) in daftarTipeIzin" :key="index" :value="item.nama">
              {{item.nama}}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item label="Regulasi Izin">
          <a-select size="large" class="w-100" :value="dataIzin.regulasi" @change="(e) => handleChange(e, 'regulasi')">
            <a-select-option v-for="(item, index) in daftarRegulasi" :key="index" :value="item.nama">
              {{item.nama}}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item label="Durasi Maksimal u/ Izin">
            <a-input v-model="dataIzin.durasi_maksimal" size="large" />
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item label="Tipe Durasi">
          <a-select size="large" class="w-100" :value="dataIzin.tipe_durasi" @change="(e) => handleChange(e, 'tipe_durasi')">
            <a-select-option v-for="(item, index) in daftarTipeDurasi" :key="index" :value="item.nama">
              {{item.nama}}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :span="24">
        <a-form-item label="Deskripsi Izin">
          <a-textarea
            v-model="dataIzin.deskripsi"
            :auto-size="{ minRows: 5, maxRows: 7 }"
          />
        </a-form-item>
      </a-col>
    </a-row>
  </div>
</template>

<script>
const daftarTipeIzin = [
  {
    nama: 'izin',
  },
  {
    nama: 'sakit',
  },
]
const daftarRegulasi = [
  {
    nama: 'Dibayar',
  },
  {
    nama: 'Tidak Dibayar',
  },
]
const daftarTipeDurasi = [
  {
    nama: 'jam',
  },
  {
    nama: 'hari',
  },
]
export default {
  props: {
    dataIzin: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      daftarTipeIzin,
      daftarRegulasi,
      daftarTipeDurasi,
      endOpen: false,
    }
  },
  methods: {
    // disabledStartDate(startValue) {
    //   const endValue = this.dataIzin.tanggal_akhir
    //   if (!startValue || !endValue) {
    //     return false
    //   }
    //   return startValue.valueOf() > endValue.valueOf()
    // },
    // disabledEndDate(endValue) {
    //   const startValue = this.dataIzin.tanggal_awal
    //   if (!endValue || !startValue) {
    //     return false
    //   }
    //   return startValue.valueOf() >= endValue.valueOf()
    // },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open
    },
    handleChange(value, column) {
      const payload = { value, column }
      this.$emit('handle-change', payload)
    },
  },
}
</script>

<style>

</style>
